//
// Version 1 - 22 August 2022
//
// Styles for Techsured website build

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeicons/primeicons.css";

// Required Bootstrap file
@import "bootstrap/scss/functions";

@import "./variable-overrides";
// Required Bootstrap file
@import "bootstrap/scss/variables";

@import "./color-overrides";
// Required Bootstrap files
@import "bootstrap/scss/_maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// Optional Bootstrap files (Used throughout components in build)
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";

// Optional Bootstrap component files (Styling options)
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/card";

@import "./utilities";

// Bootstrap utilities API to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// Additional custom style here
// Default styles

a{
  text-decoration: none;
}

p {
  font-size: $p-font-size;
}

.overlap-top {
  position: relative;
  top: -5rem;
}

ul li::marker {
  color: #4469F3;
  margin: 0;
}

// Box shadow with light blue
.box-shadow-light {
  box-shadow: 0 15px 20px rgba(68, 105, 243, 0.271);
}

// Sticky top with navigation space added
.sticky-lg-top.top-with-nav {
  top: 150px;
}

// Additional background colors
.bg-grey {
  background-color: #EEF7FB;
}
.bg-gradient-light {
  background: linear-gradient(180deg, rgba($info, 0.06) 0%, rgba($info, 0) 100%);
}
.bg-dark-v2 {
  background-color: #001F33;
}

// Box shadows
.bg-blue.box-shadow {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.059);
}

// Load font for PrimeNG components
.p-component {
  font-family: $font-family-base;
  font-display: swap;
}

// Breadcrumbs
.p-breadcrumb {
  background-color: transparent;
  padding: 0;
  border: 0;
  ul {
    display: inline-flex !important;
  }
  ul li .p-menuitem-link .p-menuitem-text {
    color: $breadcrumb-color;
    font-size: $breadcrumb-font-size;
  }
  ul li.p-breadcrumb-chevron {
    font-size: $breadcrumb-divider-size;
    padding-top: 1px; // Top position fix
    margin: 0 .25rem;
    color: $breadcrumb-divider-color;
  }
}

// Custom button
.p-button.btn {  
  padding-bottom: calc(#{$btn-padding-y} - .76px);
  display: inline-flex; // Reset back to PrimeNG default
  &.btn-sm {
    padding-bottom: calc(#{$btn-padding-y-sm} - .76px);
  }

  .p-button-label {
    font-weight: $btn-font-weight;
  }
  &:enabled:hover {
    background: var(--bs-btn-hover-bg);
    color: var(--bs-btn-hover-color);
    border-color: var(--bs-btn-hover-border-color);
  }

  &.btn-primary,
  &.btn-primary:hover,
  &.btn-secondary:hover,
  &.btn-outline-secondary:hover,
  &.btn-outline-secondary:focus,
  &.btn-outline-primary:hover,
  &.btn-outline-primary:focus {
    color: $white;
  }

  &.btn-outline-primary.bg-white {
    &:hover {
      background: $secondary !important;
    }
  }

  &.btn-link {
    font-size: $font-size-base;
    padding: 0;
    text-decoration: none;
    img {
      margin-left: .6875rem // 11px
    }
  }

  // Font weights in buttons
  &.fw-normal {
    .p-button-label {
      font-weight: $font-weight-normal
    }
  }
  &.fw-semibold {
    .p-button-label {
      font-weight: $font-weight-semibold
    }
  }
  &.fw-bolder {
    .p-button-label {
      font-weight: $font-weight-bolder
    }
  }
}

// To keep names with button hierarchy, the secondary and 
// outline-info styles has been assigned to the primary and secondary buttons
.btn-primary {
  @extend .btn-secondary;
  &.box-shadow {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.161);
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($secondary, .5);
    }
  }
  &:enabled:active {
    background-color: $secondary;
    box-shadow: 0 0 0 0.25rem rgba($secondary, .5);
    border-color: $secondary;
  }
}
.btn-outline-primary {
  @extend .btn-outline-danger;  
}
.btn-outline-secondary {
  @extend .btn-outline-info;
  background-color: transparent;
  &:enabled:active {
    background-color: transparent;
  }
}

// Custom PrimeNG Menubar
.p-menubar {
  background-color: $navbar-bg-color;
  padding: $navbar-padding-y $navbar-padding-x;
  .p-menubar-root-list  {
    margin-left: 4rem;
    gap: 2rem;
    height: 100%;
    > .p-menuitem > .p-menuitem-link {
      border-radius: 0;
      padding: 1rem;
      &:not(.p-disabled):hover {
        background: none;
        .p-menuitem-text,
        .p-submenu-icon {
          color: $secondary;
        }
      }
      > .p-menuitem-text {
        color: $navbar-color;
      }
      .p-submenu-icon {
        color: $navbar-color;
        font-size: 75%;
      }
    }
  }
  .p-menubar-end {
    .btn.btn-sm {
      padding-top: 14px;
      padding-bottom: 12px;
    }
    button + button {
      margin-left: 1.25rem;
    }
  }
}

// Navbar styles
.navbar-strip {
  background-color: $navbar-strip-bg-color;
  font-size: $navbar-strip-font-size;
  padding: .5625rem 1rem; // 9px 16px
  a {
    text-decoration: none;
    .pi {
      font-size: .625rem; // 10px
      margin-left: .5rem; // 8px
    }
  }
}
.navbar {
  display: flex;  
  background: rgba($primary, .8);
  padding: $navbar-padding-y $navbar-padding-x;
  .navbar-brand {
    display: block;
    margin-top: -2.75rem;
    @include media-breakpoint-down(xl) {
      margin-top: -2rem;
      img {
        width: auto;
        height: 90px;
      }
    }
    @include media-breakpoint-down(lg) {      
      margin-top: 0;
      img {
        height: 60px;
      }
    }
  }

  .navbar-nav {
    margin-left: 4.5rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    .nav-item {
      text-decoration: none;
      font-size: 1rem;
      color: $white;
      padding: $navbar-nav-link-padding-x;
      &:hover,
      &.active,
      &:focus {
        color: $secondary;
      }

      .pi-angle-down {
        font-size: .75rem;
      }
    }
    .navbar-dropdown {
      top: 101.5px !important;
      .red-line {
        width: 140px;
        height: 2px;
        background-color: $secondary;
        position: absolute;
        top: -2px;
        left: 0;
      }
      margin-left: $navbar-nav-link-padding-x;
      width: 100%;
      max-width: 500px;
      border-radius: 0 $border-radius $border-radius $border-radius;
      box-shadow: 0 20px 40px rgba(68, 105, 243, 0.271);
      &.p-overlaypanel:after, 
      &.p-overlaypanel:before {
        display: none;
      }
    }

    @include media-breakpoint-down(xxl) {
      margin-left: 3rem;
      gap: 2rem;
    }
    @include media-breakpoint-down(xl) {
      margin-left: 1.5rem;
      gap: .75rem;
    }
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  @include media-breakpoint-down(xl) {
    .p-button.btn.btn.btn-sm {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

// -- Mobile navigation
.mobile-nav {
  position: fixed;
  top: 117px;
  z-index: $zindex-fixed;
  width: 100%;

  &.p-accordion p-accordiontab:first-of-type .p-accordion-tab {
    border: 0;
  }
  .p-accordion-header {
    display: none;
  }
  .p-accordion-content {
    padding: 0;
    border: 0;
  }

  nav > div {    
    width: 100%;    
    height: calc(100vh - 117px);
    overflow-y: auto;
    padding: 1rem 2rem;

    .nav-item {
      text-decoration: none;
      font-size: 1.5rem;
      color: $info;
      padding: .5rem 0;
      &:hover,
      &.active,
      &:focus {
        color: $secondary;
      }

      .pi-angle-down {
        font-size: 1.125rem;
      }
    }
  }

  .mobile-nav-dropdown {
    overflow: hidden;
    &.p-accordion p-accordiontab:first-of-type .p-accordion-tab {
      border: 0;
    }
    .p-accordion-header {
      display: none;
    }
    .p-accordion-content {
      padding: 0;
      border: 0;
    }
  }
}

// Hero banners
.hero-banner {  
  background-image: var(--background-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;  
  .hero-banner-gradient {
    background: linear-gradient(90deg, rgba($white, 1) 0%, rgba($white, 0) 80%);
    @include media-breakpoint-down(xl) {
      background: linear-gradient(90deg, rgba($white, 1) 0%, rgba($white, 0) 100%);
    }
    @include media-breakpoint-down(sm) {
      background: linear-gradient(90deg, rgba($white, 1) 0%, rgba($white, .5) 100%);
    }
  }
  .hero-banner-bg-color {
    background-color: rgba($primary, .74);
  }
}

.hero-content-row{
  padding-top: 100px;
}

// Padding the size of the navbar to offset hero content right under the navbar
.pt-navbar-size {
  padding-top: 125.5px;
  @include media-breakpoint-down(xl) {
    padding-top: 113.5px;
  }
  @include media-breakpoint-down(lg) {
    padding-top: 115.5px;
  }
}

// Background image
.background-image {
  background-image: var(--background-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  .background-overlay {
    background-color: rgba($primary, .74);
  }
}


// Circle with box shadow and icon inside
.circle-box-shadow {
  background-color: $white;
  padding: 27px; // Makes the circle 100 x 100
  border-radius: 100%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.102);
  .icon-container {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  + p {
    width: 230px;
  }
}

// Dashed Separator
.dashed-separator {
  border-top: 3px dashed;
  opacity: 1;
  margin: 51px -2.8rem 0 -2.8rem; // Middle of circles
  &.mobile-vr {
    @include media-breakpoint-down(md) {
      margin: 1rem 0;
      border-top: 0;
      border-right: 3px dashed;
      min-height: 2rem;
    }
  }
}

// Borders
.border-dashed {
  border-style: dashed;
}

// Card styles
.card {
  box-shadow: $card-box-shadow;
  .card-footer-bg {
    margin-top: -1.5rem;
    padding-top: 2.5rem;
    background-color: $light;
    width: 100%;
    border-radius: 0 0 $card-border-radius $card-border-radius;
  }
}

// Carousel styling (Insurance products)
.p-carousel.product-carousel {
  .p-carousel-content {  
    .p-carousel-items-content {
      margin: 0 -.75rem;
    }
  }
  @include media-breakpoint-down(md) {
    .p-carousel-content {  
      .p-carousel-items-content {
        .p-button.btn.btn.btn-sm {
          padding-left: .5rem;
          padding-right: .5rem;
        }
      }
    }
  }
}

// -- Carousel next and previous buttons (arrows)
.p-carousel .p-carousel-content .p-carousel-prev, 
.p-carousel .p-carousel-content .p-carousel-next {
  border-radius: 4px;
  background-color: transparent;
  width: 2.5rem;
  height: auto;
  margin: 0;  
  &:enabled:hover {
    background-color: transparent;
    .pi {
      color: darken($secondary, 10%);
    }
  }
  .pi {
    color: $secondary;
    font-size: 3rem;
  }

  @include media-breakpoint-down(md) {
    .pi {
      font-size: 2.5rem;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($info, .5);
  }
}

.p-carousel .p-carousel-content .p-carousel-prev {
  right: -.5rem;
}
.p-carousel .p-carousel-content .p-carousel-next {
  left: -.5rem;
}

// -- Carousel indicators
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  button {
    width: .75rem;
    height: .75rem;
    border-radius: 100%;
    background-color: $info;
    opacity: .26;
  }
  &.p-highlight button {
    opacity: 1;
    background-color: $info;
  }
}

// Star ratings
.p-rating .p-rating-icon {
  color: $info;
  font-size: 1.75rem;
  &.pi-star-fill {
    color: $info;
  }
}

// Accordion styles
.p-accordion p-accordiontab {
  &:first-of-type .p-accordion-tab {
    border-top: 1px solid rgba($dark, .09);
  }
  .p-accordion-tab {
    border-bottom: 1px solid rgba($dark, .09);
    margin-bottom: 0;
  }
}
// -- Header styles
.p-accordion .p-accordion-header {
  .p-accordion-header-link {
    padding: 1.75rem 0;
    font-size: 1.625rem;
    border: 0;
    border-radius: 0;
    color: rgba($dark, .62);
    background-color: transparent;
  }
  &:not(.p-disabled).p-highlight .p-accordion-header-link {
    background-color: transparent;
    color: $dark;
  }

  // Hover state
  &:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
  &:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    color: $dark;
    background-color: transparent;
  }

  @include media-breakpoint-down(lg) {
    .p-accordion-header-link {
      padding: 1.5rem 0;
      font-size: 1.25rem;
      .p-accordion-header-text {
        line-height: 1.4;
        padding-right: 1.25rem;
      }
    }
  }
}

// -- Accordion Focus styles
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: 0 0 0 0.2rem rgba($info, .16);
  border-radius: $border-radius;
}

// -- Accordion Content styles
.p-accordion .p-accordion-content {
  font-size: 1rem;
  color: $body-color;
  padding: 0 0 2.25rem 0;
  border: 0;
}
// -- Open/Close icon switch to the right
.p-accordion-toggle-icon {
  order: 1;
  margin-left: auto;
  color: $dark;
  font-size: 1.25rem;
}

// Chips (Tags)
.p-chip {
  &.bg-info {
    background-color: rgba($info, 0.149) !important; // Create background opacity
    .p-chip-icon {
      color: $dark;
    }
  }
}

// Avatars
.p-avatar-group p-avatar + p-avatar {
  margin-left: -0.75rem !important;
}
.p-avatar.logo-image {
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
}
.p-avatar.p-avatar-xl.p-avatar-xxl {
  width: 4.5rem;
  height: 4.5rem;
}

// Form styles
// -- Inputs
.p-inputtext,
.p-dropdown {
  font-family: $font-family-base;
  font-display: swap;
  border-radius: $border-radius;
  border: 0;
  box-shadow: 0px 3px 6px rgba(0, 28, 45, 0.11);

  &::placeholder,
  .p-dropdown-label.p-placeholder {
    color: rgba(#000000, .63);
  }
}

// -- Dropdowns
.p-dropdown {
  .p-dropdown-trigger-icon {
    color: $secondary;    
  }
  .p-dropdown-trigger {
    @include media-breakpoint-down(xl) {
      width: 2rem;
      margin-right: .5rem;
    }
  }
  &.icon-blue {
    .p-dropdown-trigger-icon {
      color: #056AA8;
    } 
  }
  .p-inputtext {
    border-radius: 0;
    box-shadow: none;
  }
  &.fs-7 {
    .p-dropdown-label {
      font-size: $p-font-size;
    }
  }
}

// -- Checkboxes
.blue-checkbox {
  &.p-checkbox .p-checkbox-box {
    border: 0;
    width: 18px;
    height: 18px;
    color: $white;
    border-radius: $border-radius;
    background-color: rgba($info, .7);
  }
  &.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: none;
  }
  &.p-checkbox .p-checkbox-box .p-checkbox-icon {
    font-size: 12px;
  }
  &.p-checkbox .p-checkbox-box.p-highlight {
    background-color: $info;
  }
  &.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: 0 0 0 0.2rem rgba($info, .5);
  }
}

// -- Focus states
.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba($info, .5);
  border-color: $info;
}

// DataView grid styles
.articles-grid {
  .p-dataview-header {
    background-color: transparent;
    border: 0;
    .search-input {
      position: relative;
      background-color: $white;      
      box-shadow: 0 3px 6px rgba(0, 28, 45, 0.102);
      width: 100%;
      max-width: 420px;
      margin: -2.7rem auto 0 auto;
      border-radius: $border-radius;
      input {
        padding: 1rem 1rem 1rem 4rem;
        width: 100%;
        font-size: $p-font-size;
        background-color: transparent;
        border: 0;
        border-radius: $border-radius;
        &:enabled:focus {
          border-radius: $border-radius;
          box-shadow: 0 0 0 0.2rem rgba($info, .25);
        }
      }
      &::before {
        content: url('/assets/icons/icon-search.svg');
        width: 26px;
        height: 26px;
        position: absolute;
        top: calc(50% - 2px);
        left: 1rem;
        transform: translateY(-50%);
      }

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }
  }
}

// -- Enable grid styles
.p-grid {
  @extend .row;
  --bs-gutter-x: 4rem;
  --bs-gutter-y: 4rem;
  margin-top: 0;
  margin-bottom: 4rem;
  @include media-breakpoint-down(lg) {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 2rem;
    margin-bottom: 2rem;
  }
  @include media-breakpoint-down(md) {
    --bs-gutter-x: 0;
    --bs-gutter-y: 3rem;
    margin-bottom: 0;
  }
}

// -- Data view filter links
.dataview-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  .dataview-link {
    input {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    label {
      cursor: pointer;
      font-size: 1.75rem;
      font-weight: 700;
      color: rgba($primary, .5);
      &:focus {
        outline: none;
        border-radius: $border-radius;
        box-shadow: 0 0 0 0.2rem rgba($info, .25);
      }
    }
    input:checked {
      + label {
        color: $secondary;
      }      
    }    
  }

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }
  @include media-breakpoint-down(sm) {
    flex-wrap: nowrap;
    overflow-x: auto;
    align-items: start;
    justify-content: start;
    padding: .5rem 0;
  }
}

// Articles
.article-highlight {
  background-color: $light;
  box-shadow: 0 10px 15px rgba($info, 0.2);
  padding: 3rem;
  margin-left: -2rem;

  @include media-breakpoint-down(lg) {
    padding: 1.5rem;
    margin-left: 0;
  }
}

// Modals
.modal-bg-dark {
  background-color: #001F33;
  &.p-dialog .p-dialog-header,
  &.p-dialog .p-dialog-content,
  &.p-dialog .p-dialog-footer {
    background: transparent;
  }
}

.modal-sm {
  width: 100%;
  max-width: 600px;

  &.p-dialog .p-dialog-header-icons {
    position: absolute;
    top: 5px;
    right: 5px;    
  }
  &.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #056AA8;
    &:enabled:hover {
      background-color: rgba($info, .5);
      color: $white;
    }
  }

  .p-dialog-footer {
    padding: 0;
    text-align: center;
  }
}

// -- Modal mask
.mask-dark {
  --maskbg: rgba(0, 20, 33, 0.922);
}

// File Upload styles
.p-fileupload .p-fileupload-row {
  color: $white;
  border-bottom: 1px solid rgba($info, .5);
  > div:first-of-type {
    flex: 0 1 auto;
    width: 50px;
    margin-right: 1rem;
  }

  .p-button {
    background-color: transparent;
    border: 1px solid $info;
    color: $info;
    padding: 0.5rem 0.75rem;
    width: auto;
    border-radius: $border-radius;
    &:enabled:hover {
      background-color: $info;
      border: 1px solid $info;
      color: $white;
    }
  }
}

.p-fileupload .p-fileupload-buttonbar {
  background-color: transparent;
  border: 0;
  padding: 0;
  .p-button {
    background-color: transparent;
    color: rgba($info, .62);
    font-size: $p-font-size;
    font-weight: 700;
    border: 3px dashed $blue;
    border-radius: $border-radius;
    width: 100%;
    padding: 1.5rem;
    &.p-fileupload-choose:not(.p-disabled):hover {
      color: $info;
    }
    &:focus,
    &.p-fileupload-choose.p-focus {
      box-shadow: 0 0 0 0.25rem rgba($info, .5);
    }
  }
}

.p-fileupload .p-fileupload-content {
  padding: 0;
  border: 0;
  background-color: transparent;
  .p-progressbar {
    background-color: transparent;
  }
}

.preloader {
  width: 100vw;
  height: 100vh;
  background-color: #00263E;
  color: #FFF;
  position: relative;
  z-index: -999999999;
  display: flex;
  flex-direction: column;      
  align-items: center;
  justify-content: center;
  text-align: center;
}
.preloader-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
}

.call-consent {
  color: white;
}

.container-lg {
  padding-top: 1em;
  padding-bottom: 3em;
}

.text-col-one {
  margin-right: 2em !important;
}

.p-checkbox{
  display: flex;
  flex-direction: row;      
  align-items: flex-start;
  // justify-content: center;
  // text-align: center;
}

//Intellicell page --temp

#intelliNav {
  height: 75px;
  background-color: white !important;
}

#intelliNavbar {
  background-color: white !important;
}

#intelliLogo {
  margin-top: 0px;
}

#intelliBtn{
  background-color: #00263E;
  border: 1px solid #00263E;
  box-shadow: none;
}

//Best4u page --temp

#best4uNav {
  height: 75px;
  background-color: white !important;
}

#best4uNavbar {
  background-color: white !important;
}

#best4uLogo {
  margin-top: 0px;
}

#best4uBtn{
  background-color: #F4931D;
  border: 1px solid #F4931D;
  box-shadow: none;
  color: #1F1E33;
}

@media screen and (max-width: 728px) {
  .total-value-row {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    text-align: center !important;
  }

  .total-value-row .col-8,
  .total-value-row .col-4 {
    width: 100% !important;
  }

  .total-value-row .col-4 {
    justify-content: center !important;
  }

  .total-value-row .col-4 h4,
  .total-value-row .col-4 h3,
  .total-value-row .col-4 h4 {
    margin: 0 !important;
  }
}