// Bootstrap variable overrides and some new custom variables
// -- Color variables
$primary: #00263E;
$secondary: #EB3B24;
$dark: #1F1E33;
$info: #37B3F1;
$light: #EEF7FB;
$white: #FFFFFF;
$pink: #C1438F;
$grey: #F1F0F1;
$blue: #003354; 
$danger: $secondary;
$success: #00C999;
$warning: #F4931D;
$black: #000000;

$body-color: rgba($dark, .8);
$link-color: $info;

// -- Navbar variables
$navbar-bg-color: rgba($primary, .8);
$navbar-strip-bg-color: rgba(#023657, .9);
$navbar-color: $white;
$navbar-strip-font-size: .8125rem; // 13px
$navbar-padding-y: .5625rem; // 9px
$navbar-padding-x: 1.375rem; // 22px

// -- Button variables
$btn-border-radius: 5px;
$btn-padding-y: .875rem; // 14px
$btn-padding-x: 3.931rem;
$btn-font-size: 1.3125rem; // 21px
$btn-line-height: 1.3333333; // 28px
$btn-font-weight: 700;

$btn-padding-y-sm: .625rem; // 10px
$btn-padding-x-sm: 1.875rem; // 30px
$btn-font-size-sm: 1.125rem; // 18px

// -- Typography variables
$font-family-base: 'Roboto', sans-serif;

$font-size-base: 1rem; // 16px
$h1-font-size: $font-size-base * 4.625; // 74px
$h2-font-size: $font-size-base * 3.5; // 56px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.375;  // 22px
$h6-font-size: $font-size-base * 1.25; // 20px
$p-font-size: $font-size-base * 1.125; // 18px

$font-weight-semibold: 500;
$font-weight-bolder: 900;

// Borders
$border-radius: 5px;

// -- Breadcrumb variables
$breadcrumb-color: #A8A8A8;
$breadcrumb-divider-color: #B1B1B1;
$breadcrumb-divider-size: .5rem;
$breadcrumb-font-size: .875rem; // 14px

// -- Card variables
$card-border-radius: $border-radius;
$card-inner-border-radius: $card-border-radius;
$card-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.059);
